import { get } from "lodash";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Button, Col, Container, Row } from "reactstrap";
import { IRootState } from "src/store/reducers";
import {
  deleteBrokerDocument,
  downloadBrokerDocument,
  fetchBrokerDocuments,
  toggleBrokerUploadToast,
  uploadBrokerDocument,
} from "src/store/reducers/brokerDocuments";
import { saveToDownloads } from "src/util/util.fileService";
import LauncherModal from "../../components/LauncherModal/LauncherModal";
import DisTable from "../../components/Table/Table";
import Toast from "../../components/Toast/Toast";
import UploadDocumentModal from "../../components/UploadDocumentModal/UploadDocumentModal";
import * as S from "../../constants/StringConstants";
import { IDocument } from "../../store/models/document.model";
import { iso8601ToShort } from "../../util/utils.dates";

import "./BrokerDocs.scss";

export interface BrokerDocsProps {}

const BrokerDocs = (props: BrokerDocsProps) => {
  const fileTableRef = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadModal, toggleUploadModal] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { id: brokerID } = useParams<{ id: string }>();

  const { isUploadToastVisible } = useSelector((state: IRootState) => state.brokerDocuments);

  const columns = [
    {
      key: "fileName",
      title: S.BRO_DOC_NAME_COL,
      render: item =>
        item.fileName && (
          <a
            className="nowrap"
            title={item.fileName}
            onClick={async e => {
              e.preventDefault();
              setLoading(true);
              await downloadDocument(item);
              setLoading(false);
            }}>
            {item.fileName}
          </a>
        ),
    },
    { key: "modifiedBy", title: S.BRO_DOC_MODIFIED_BY_COL, style: { maxWidth: "100px" } },
    {
      key: "modifiedDate",
      title: S.BRO_DOC_MODIFIED_DATE_COL,
      render: item => {
        return iso8601ToShort(item.modifiedDate);
      },
    },
    {
      key: "delete",
      title: S.BRO_DOC_DELETE_COL,
      render: item => {
        return (
          <LauncherModal
            title={S.BRO_DOC_DELETE_CONFIRM_TEXT}
            launcher={
              <Button
                onClick={e => {
                  e.preventDefault();
                }}>
                {S.BRO_DOC_DELETE_BUTTON}
              </Button>
            }
            onConfirm={() => {
              deleteDocument(item);
            }}
          />
        );
      },
    },
  ];

  const uploadFiles = async (acceptedFiles: File[]) => {
    setLoading(true);
    try {
      for (const acceptedFile of acceptedFiles) {
        try {
          await dispatch(uploadBrokerDocument(brokerID, acceptedFile));
        } catch (e) {
          alert(S.BRO_DOC_UPLOAD_ERROR);
        } finally {
        }
      }

      if (fileTableRef) {
        fileTableRef.current.update({});
      }
    } catch (e) {
    } finally {
      toggleUploadModal(false);
      setLoading(false);
    }
  };

  const downloadDocument = async (document: IDocument) => {
    const result = await dispatch(downloadBrokerDocument(brokerID, `${document.documentID}`));
    const fileName = get(result, "value.headers.x-attachment-filename");
    const data = get(result, "value.data");
    if (fileName && data) {
      saveToDownloads(fileName, "application/pdf", data);
    }
  };

  const deleteDocument = async (document: IDocument) => {
    try {
      await dispatch(deleteBrokerDocument(brokerID, `${document.documentID}`));
      if (fileTableRef) {
        fileTableRef.current.update();
      }
    } catch (e) {}
  };

  return (
    <main className="broker-docs__wrap">
      <section className="broker-docs__container">
        <div className="broker-docs__builder">
          <div className="broker-docs__form-wrap">
            <Container>
              <Row>
                <Toast open={loading} message={S.LOADING} onClose={() => {}} />
                <Toast
                  open={isUploadToastVisible}
                  message={S.BRO_DOC_FILE_UPLOAD_SUCCESS_TOAST}
                  onClose={() => {
                    dispatch(toggleBrokerUploadToast(false));
                  }}
                />
                <UploadDocumentModal
                  open={uploadModal}
                  handleFiles={uploadFiles}
                  onCancel={() => {
                    toggleUploadModal(false);
                  }}
                />
                <Col sm="9" className="doc-tab__title">
                  <h1 className="heading3">{S.BRO_DOC_DOCUMENTS_TITLE}</h1>
                </Col>
                <Col sm="3">
                  <button
                    className="button__white"
                    onClick={e => {
                      e.preventDefault();
                      toggleUploadModal(true);
                    }}>
                    {S.BRO_DOC_ADD_DOCUMENT_BUTTON}
                  </button>
                </Col>
              </Row>
              <Row>
                <Col className="doc-tab__table-override">
                  <DisTable<IDocument>
                    columns={columns}
                    initialState={{}}
                    isPaginated={false}
                    getRef={ft => {
                      fileTableRef.current = ft;
                    }}
                    onUpdate={async ({ page, rows, sort, query }) => {
                      const result = await dispatch(fetchBrokerDocuments(brokerID));
                      const data = get(result, "value.data");
                      return {
                        total: 0,
                        data: data as IDocument[],
                      };
                    }}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </main>
  );
};

export default BrokerDocs;
