import { Button, Icon } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { withStyles } from "@mui/styles";
import React from "react";
import Dropzone from "react-dropzone";
import { Field, Form as FinalForm } from "react-final-form";
import { Col, Row } from "reactstrap";

import { connect } from "react-redux";
import SimpleDatePickerField from "src/components/Form/SimpleDatePickerField/SimpleDatePickerField";
import SingleSelectField from "src/components/Form/SingleSelectField/SingleSelectField";
import TextField from "src/components/Form/TextField/TextField";
import { IRootState } from "src/store/reducers";
import { ContractingModalData, fetchBrokerContractsOptions } from "src/store/reducers/brokerContracts";
import { addDefaultOption } from "src/util/utils.defaultValues";
import * as S from "../../constants/StringConstants";
import "./ContractingModal.scss";

export interface UploadDocumentModalProps extends StateProps, DispatchProps {
  accept?: string;
  brokerID: string;
  carrierID?: number;
  open: boolean;
  handleSave: (data: ContractingModalData) => void;
  onCancel: () => void;
  item?: ContractingModalData;
}

interface UploadDocumentModalState {
  carrierID?: number;
  documentID?: number;
  file?: File;
  carrierTypeID?: number;
  statusTypeID?: number;
  licenseNumber?: string;
  expirationDate?: string;
}

const DialogActions = withStyles(theme => ({
  root: {
    marginBottom: "1,5rem",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}))(MuiDialogActions);

class ContractingModal extends React.Component<UploadDocumentModalProps, UploadDocumentModalState> {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.fetchBrokerContractsOptions(this.props.brokerID);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.carrierID !== state.carrierID && !state.carrierID) {
      return {
        carrierID: props.carrierID,
      };
    }
    return null;
  }

  handleDrop = (accepted, rejected) => {
    if (accepted.length > 0) {
      this.setState(state => ({
        file: accepted,
      }));
    }
  };

  handleClickCancel = () => {
    const { onCancel } = this.props;
    onCancel();
    this.clearFiles();
  };

  handleClickUpload = (values: ContractingModalData) => {
    const { handleSave } = this.props;
    if (!this.state.carrierID && values.carrierTypeID) {
      this.setState({ carrierID: values.carrierTypeID });
      return;
    }
    handleSave(values);
    this.clearFiles();
  };

  clearFiles = () => {
    this.setState(state => ({
      file: null,
    }));
  };

  render() {
    const {
      open,
      onCancel,
      brokerContracts: {
        options: { selectCarrierList, selectStatusTypeList },
      },
      item,
    } = this.props;

    const { carrierID } = this.state;

    return (
      <div>
        <Dialog
          open={open}
          PaperProps={{
            style: {
              width: "64rem",
            },
          }}>
          <FinalForm
            onSubmit={this.handleClickUpload}
            initialValues={item || {}}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            render={({ form, handleSubmit, ...formProps }) => (
              <form onSubmit={handleSubmit} className="contracting-modal">
                <DialogTitle>
                  {S.UDM_TITLE}
                  {` `}
                  {carrierID ? selectCarrierList.find(c => c.value === carrierID).key : ""}
                </DialogTitle>
                <DialogContent>
                  {!carrierID ? (
                    <Row>
                      <Col sm="6">
                        <SingleSelectField
                          name="carrierTypeID"
                          label={S.BRO_CONTRACTING_CARRIER}
                          options={addDefaultOption("", selectCarrierList)}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <Row>
                        <Col sm="4">
                          <SingleSelectField
                            name="statusTypeID"
                            label={S.BRO_CONTRACTING_STATUS}
                            options={addDefaultOption("", selectStatusTypeList)}
                          />
                        </Col>
                        <Col sm="4">
                          <TextField name="licenseNumber" label={S.BRO_CONTRACTING_LICENSE} />
                        </Col>
                        <Col sm="4">
                          <SimpleDatePickerField name="expirationDate" label={S.BRO_CONTRACTING_EXPIRATION_DATE} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>{S.BRO_CONTRACTING_NOTES}</label>
                          <Field
                            type="textarea"
                            component="textarea"
                            name="notes"
                            className="contracting-modal__textarea"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>{S.BRO_CONTRACTING_ATTACH_FILE}</label>
                          <Field name="file">
                            {field => (
                              <Dropzone
                                accept={this.props.accept}
                                onDrop={file => form.mutators.setValue("file", file[0])}>
                                {({ getRootProps, getInputProps, isDragActive }) => (
                                  <div className="dropzone" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p>{S.UDM_DROPZONE}</p>
                                  </div>
                                )}
                              </Dropzone>
                            )}
                          </Field>
                          {formProps.values.file && (
                            <Row className="file align-items-center">
                              <Col className="col-auto mr-auto">{formProps.values.file.name}</Col>
                              <Col className="col-auto">
                                <Button
                                  variant="contained"
                                  component="span"
                                  className="button5 button__orange"
                                  onClick={e => form.mutators.setValue("file", null)}
                                  size="large">
                                  {S.UDM_DELETE_BUTTON}
                                  <Icon>delete</Icon>
                                </Button>
                              </Col>
                            </Row>
                          )}
                          {formProps.values.fileName && (
                            <Row className="file align-items-center">
                              <Col className="col-auto mr-auto">{formProps.values.fileName}</Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                      {formProps.error && formProps.dirty && <div className="dropzone__error">{formProps.error}</div>}
                    </>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button className="dropzone__cancel-button" onClick={this.handleClickCancel}>
                    {S.UDM_CANCEL_BUTTON}
                  </Button>
                  <Button className="dropzone__upload-button" type="submit">
                    {S.BRO_CONTRACTING_SAVE}
                  </Button>
                </DialogActions>
              </form>
            )}
          />
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = ({ brokerContracts }: IRootState) => ({
  brokerContracts,
});

const mapDispatchToProps = {
  fetchBrokerContractsOptions,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ContractingModal);
