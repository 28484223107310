import { get, groupBy } from "lodash";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Button, Col, Container, Row } from "reactstrap";
import { IRootState } from "src/store/reducers";
import {
  ContractingModalData,
  deleteBrokerContract,
  downloadBrokerContract,
  fetchBrokerContracts,
  toggleBrokerUploadToast,
  uploadBrokerContract,
} from "src/store/reducers/brokerContracts";
import { saveToDownloads } from "src/util/util.fileService";
import LauncherModal from "../../components/LauncherModal/LauncherModal";
import DisTable from "../../components/Table/Table";
import Toast from "../../components/Toast/Toast";
import * as S from "../../constants/StringConstants";
import { IDocument } from "../../store/models/document.model";
import { iso8601ToShort } from "../../util/utils.dates";

import { Icon, Radio, Tooltip } from "@mui/material";
import moment from "moment";
import "./BrokerDocs.scss";
import ContractingModal from "./ContractingModal";

const getCarrierBucketList = b => {
  return Object.keys(b).map(k => ({
    id: parseInt(k),
    carrierTypeID: parseInt(k),
  }));
};

const createBucketsByCarrier = result => {
  const data = get(result, "value.data");
  const g = groupBy(data, item => item.carrierTypeID);
  return g;
};

export interface IDocumentCarrier {
  id: number;
  carrierTypeID: number;
}

export interface BrokerContractsProps {}

const BrokerContracts = (props: BrokerContractsProps) => {
  const carrierTableRef = useRef<any>(null);
  const fileTableRef = useRef<any>(null);
  const [carrierID, setCarrierID] = useState<number | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [buckets, setBuckets] = useState([]);
  const [loadingTimeHack, setLoadingTimeHack] = useState(0);
  const [item, setItem] = useState<ContractingModalData | null>(null);
  const [uploadModal, toggleUploadModal] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { id: brokerID } = useParams<{ id: string }>();

  const {
    isUploadToastVisible,
    options: { selectCarrierList, selectStatusTypeList },
  } = useSelector((state: IRootState) => state.brokerContracts);

  const carrierColumns = [
    {
      key: "radio",
      title: "",
      style: { width: "5rem" },
      render: item => {
        return (
          <>
            <Radio
              name="selectedCarrier"
              onClick={() => setCarrierID(item.carrierTypeID)}
              checked={carrierID === item.carrierTypeID}
            />
          </>
        );
      },
    },
    {
      key: "carrierTypeID",
      title: S.BRO_CONTRACTING_CARRIER,
      render: item => {
        return <>{selectCarrierList.find(c => c.value === item.carrierTypeID)?.key}</>;
      },
    },
    {
      key: "addDoc",
      title: "Add document",
      style: { width: "10rem", textAlign: "center" },
      render: item => {
        return (
          <Button
            disabled={!carrierID && carrierID === item.carrierTypeID}
            style={{ width: "10rem" }}
            onClick={e => {
              e.preventDefault();
              setItem(item);
              toggleUploadModal(true);
            }}>
            {S.BRO_DOC_ADD_DOCUMENT_BUTTON}
          </Button>
        );
      },
    },
  ];

  const docColumns = [
    {
      key: "active",
      title: S.BRO_CONTRACTING_ACTIVE,
      render: item => <>{moment().isAfter(item.expirationDate) ? <Icon>close</Icon> : <Icon>check</Icon>}</>,
    },
    {
      key: "fileName",
      title: S.BRO_DOC_NAME_COL,
      render: item =>
        item.fileName ? (
          <a
            className="nowrap"
            title={item.fileName}
            onClick={async e => {
              e.preventDefault();
              setLoading(true);
              await downloadDocument(item);
              setLoading(false);
            }}>
            {item.fileName}
          </a>
        ) : (
          <span>{S.BRO_DOC_NO_FILE}</span>
        ),
    },

    {
      key: "statusTypeID",
      title: S.BRO_CONTRACTING_STATUS,
      render: item => {
        return <>{selectStatusTypeList.find(c => c.value === item.statusTypeID)?.key}</>;
      },
    },
    { key: "licenseNumber", title: S.BRO_CONTRACTING_LICENSE },
    {
      key: "expirationDate",
      title: S.BRO_CONTRACTING_EXPIRATION_DATE,
      render: item => {
        return iso8601ToShort(item.expirationDate);
      },
    },
    {
      key: "notes",
      title: "Notes",
      render: item => (
        <Tooltip title={item.notes || "-"}>
          <Icon>info</Icon>
        </Tooltip>
      ),
    },
    {
      key: "modifiedDate",
      title: S.BRO_DOC_MODIFIED_DATE_COL,
      render: item => {
        return (
          <Tooltip title={item.modifiedBy}>
            <span>{iso8601ToShort(item.modifiedDate)}</span>
          </Tooltip>
        );
      },
    },
    {
      key: "edit",
      title: "Edit",
      style: { width: "8rem", textAlign: "center" },
      render: item => {
        return (
          <Button
            style={{ width: "7rem" }}
            onClick={e => {
              e.preventDefault();
              setItem(item);
              toggleUploadModal(true);
            }}>
            {S.BRO_DOC_EDIT_BUTTON}
          </Button>
        );
      },
    },
    {
      key: "delete",
      title: S.BRO_DOC_DELETE_COL,
      style: { width: "8rem", textAlign: "center" },
      render: item => {
        return (
          <LauncherModal
            title={S.BRO_DOC_DELETE_CONFIRM_TEXT}
            launcher={
              <Button
                style={{ width: "7rem" }}
                onClick={e => {
                  e.preventDefault();
                }}>
                {S.BRO_DOC_DELETE_BUTTON}
              </Button>
            }
            onConfirm={() => {
              deleteDocument(item);
            }}
          />
        );
      },
    },
  ];

  const uploadFiles = async (data: ContractingModalData) => {
    setLoading(true);
    try {
      try {
        await dispatch(uploadBrokerContract(brokerID, data));
      } catch (e) {
        alert(S.BRO_DOC_UPLOAD_ERROR);
      } finally {
      }
      carrierTableRef?.current?.update({});
    } catch (e) {
    } finally {
      toggleUploadModal(false);
      setLoading(false);
    }
  };

  const deleteDocument = async (document: IDocument) => {
    try {
      await dispatch(deleteBrokerContract(brokerID, `${document.documentID}`));
      carrierTableRef?.current?.update({});
    } catch (e) {
      alert(e);
    }
  };

  const downloadDocument = async (document: IDocument) => {
    try {
      const result = await dispatch(downloadBrokerContract(brokerID, `${document.documentID}`));
      const fileName = get(result, "value.headers.x-attachment-filename");
      const data = get(result, "value.data");
      if (fileName && data) {
        saveToDownloads(fileName, "application/pdf", data);
      }
    } catch (e) {
      alert(e);
    }
  };

  return (
    <main className="broker-docs__wrap">
      <section className="broker-docs__container">
        <div className="broker-docs__builder">
          <div className="broker-docs__form-wrap">
            <Container>
              <Row>
                <Toast open={loading} message={S.LOADING} onClose={() => {}} />
                <Toast
                  open={isUploadToastVisible}
                  message={S.BRO_DOC_FILE_UPLOAD_SUCCESS_TOAST}
                  onClose={() => {
                    dispatch(toggleBrokerUploadToast(false));
                  }}
                />
                <ContractingModal
                  carrierID={carrierID}
                  brokerID={brokerID}
                  open={uploadModal}
                  item={item}
                  handleSave={uploadFiles}
                  onCancel={() => {
                    toggleUploadModal(false);
                  }}
                />
                <Col sm="9" className="doc-tab__title">
                  <h1 className="heading3">{S.BRO_CONTRACTING_TITLE}</h1>
                </Col>
                <Col sm="3">
                  <button
                    className="button__white"
                    onClick={e => {
                      e.preventDefault();
                      setCarrierID(null);
                      setItem(null);
                      toggleUploadModal(true);
                    }}>
                    {S.BRO_DOC_ADD_CARRIER_BUTTON}
                  </button>
                </Col>
              </Row>
              <Row>
                <Col className="doc-tab__table-override">
                  <DisTable<IDocumentCarrier>
                    columns={carrierColumns}
                    initialState={{}}
                    isPaginated={false}
                    getRef={ft => {
                      carrierTableRef.current = ft;
                    }}
                    onUpdate={async ({ page, rows, sort, query }) => {
                      setLoading(true);
                      const result = await dispatch(fetchBrokerContracts(brokerID));
                      setLoading(false);
                      const buckets = createBucketsByCarrier(result);
                      const bucketList = getCarrierBucketList(buckets);
                      setBuckets(buckets);
                      setLoadingTimeHack(new Date().getTime());
                      return {
                        total: 0,
                        data: bucketList,
                      };
                    }}
                  />
                </Col>
              </Row>
              {carrierID && (
                <Row key={`carrier-${carrierID}-${loadingTimeHack}`}>
                  <Col className="doc-tab__table-override">
                    <DisTable<IDocument>
                      columns={docColumns}
                      initialState={{}}
                      isPaginated={false}
                      onUpdate={async ({ page, rows, sort, query }) => {
                        const data = buckets[carrierID];
                        return {
                          total: 0,
                          data: (data as IDocument[]).map(d => ({ id: d.documentID, ...d })),
                        };
                      }}
                    />
                  </Col>
                </Row>
              )}
            </Container>
          </div>
        </div>
      </section>
    </main>
  );
};

export default BrokerContracts;
