import axios from "axios";
import { get, set } from "lodash";
import { IOption } from "../models/option.model";
import { FULFILLED, PENDING, REJECTED } from "./action-type.util";

const remapItem = item => ({ id: item["documentID"], ...item });
const remapItems = items => items.map(remapItem);

export const ACTION_TYPES = {
  FETCH_DOCUMENTS: "brokerDocuments/FETCH_DOCUMENTS",
  DOWNLOAD_DOCUMENT: "brokerDocuments/DOWNLOAD_DOCUMENT",
  UPLOAD_FILE: "brokerDocuments/UPLOAD_FILE",
  TOGGLE_UPLOAD_TOAST: "brokerDocuments/TOGGLE_UPLOAD_TOAST",
  DOWNLOAD_FILE: "brokerDocuments/DOWNLOAD_FILE",
  DELETE_FILE: "brokerDocuments/DELETE_FILE",
};

const initialState = {
  documents: [],
  inProcess: false,
  uploadModal: {
    documentTypes: new Array<IOption>(),
  },
  isUploadToastVisible: false,
};

export type BrokerDocumentsState = Readonly<typeof initialState>;

/**
 * Correspondence Reducer
 */
export default (state: BrokerDocumentsState = initialState, action): BrokerDocumentsState => {
  switch (action.type) {
    // Pending
    case PENDING(ACTION_TYPES.UPLOAD_FILE):
    case PENDING(ACTION_TYPES.FETCH_DOCUMENTS):
    case PENDING(ACTION_TYPES.DOWNLOAD_DOCUMENT): {
      return {
        ...state,
      };
    }

    // Fulfilled
    case FULFILLED(ACTION_TYPES.DOWNLOAD_DOCUMENT): {
      return {
        ...state,
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_DOCUMENTS): {
      return {
        ...state,
        documents: action.payload.data,
      };
    }

    // Rejected
    case REJECTED(ACTION_TYPES.DOWNLOAD_DOCUMENT):
    case REJECTED(ACTION_TYPES.FETCH_DOCUMENTS): {
      return {
        ...state,
      };
    }

    case FULFILLED(ACTION_TYPES.UPLOAD_FILE): {
      return {
        ...state,
        inProcess: false,
        isUploadToastVisible: true,
      };
    }

    case ACTION_TYPES.TOGGLE_UPLOAD_TOAST: {
      const isUploadToastVisible = get(action, "meta.open");
      return {
        ...state,
        isUploadToastVisible,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export const fetchBrokerDocuments = (brokerID: string) => {
  return {
    type: ACTION_TYPES.FETCH_DOCUMENTS,
    payload: axios.get(`brokers/${brokerID}/documents`).then(response => {
      const items = get(response, "data", null);
      if (items) set(response, "data", remapItems(items));
      return response;
    }),
  };
};

export const uploadBrokerDocument = (brokerID: string, file: any) => {
  const formData = new FormData();
  formData.append("FormFile", file);
  return {
    type: ACTION_TYPES.UPLOAD_FILE,
    payload: axios.post(`brokers/${brokerID}/documents`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    }),
  };
};

export const downloadBrokerDocument = (brokerID: string, documentID: string) => {
  return {
    type: ACTION_TYPES.DOWNLOAD_FILE,
    payload: axios.get(`brokers/${brokerID}/documents/${documentID}`, { responseType: "blob" }),
  };
};

export const deleteBrokerDocument = (proposalID: string, documentID: string) => {
  return {
    type: ACTION_TYPES.DELETE_FILE,
    payload: axios.delete(`brokers/${proposalID}/documents/${documentID}`),
  };
};

export const toggleBrokerUploadToast = (open: boolean) => {
  return {
    type: ACTION_TYPES.TOGGLE_UPLOAD_TOAST,
    meta: { open },
  };
};
