import cx from "classnames";
import React from "react";

import { Redirect, Route, Switch, useHistory, useLocation, useParams } from "react-router";
import * as S from "../../constants/StringConstants";
import BrokerBuilder from "./BrokerBuilder";
import "./BrokerBuilder.scss";
import BrokerContracts from "./BrokerContracts";
import BrokerDocs from "./BrokerDocs";

export const BrokerContainer = props => {
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  // Highlight Active Tab
  const pathNameRegex = /\/([a-zA-Z0-9]+)$/;
  const match = pathNameRegex.exec(location.pathname);
  const pathname = match ? match[1] : "";

  const isNew = id === "new";

  return (
    <div className="broker-builder">
      <h1 className="heading1 grey--light">{S.BRO_NEW_TITLE}</h1>
      <div className="proposal__links">
        <div className="proposal__tabs">
          <ul>
            <li>
              <button
                className={cx("heading5 grey", {
                  active: pathname === "details",
                })}
                onClick={e => {
                  e.preventDefault();
                  history.push(`/brokers/${id}/details`);
                }}>
                {S.BRO_DETAILS_TAB}
              </button>
            </li>
            <li>
              <button
                className={cx("heading5 grey", { active: pathname === "contracting" })}
                onClick={e => {
                  e.preventDefault();
                  if (!isNew) {
                    history.push(`/brokers/${id}/contracting`);
                  }
                }}>
                {S.BRO_CONTRACTING_TAB}
              </button>
            </li>
            <li>
              <button
                className={cx("heading5 grey", { active: pathname === "documentation" })}
                onClick={e => {
                  e.preventDefault();
                  if (!isNew) {
                    history.push(`/brokers/${id}/documentation`);
                  }
                }}>
                {S.BRO_DOCUMENTATION_TAB}
              </button>
            </li>
          </ul>
        </div>
      </div>

      <main className="proposal__wrap">
        <section className="proposal__container">
          <Switch>
            <Route
              path="/brokers/:id/details"
              render={props => {
                return <BrokerBuilder {...(props as any)} />;
              }}
            />
            <Route path="/brokers/:id/contracting" render={props => <BrokerContracts {...(props as any)} />} />
            <Route path="/brokers/:id/documentation" render={props => <BrokerDocs {...(props as any)} />} />
            <Route
              path="/brokers/new"
              render={props => {
                return <BrokerBuilder {...(props as any)} />;
              }}
            />
            {id && <Redirect to={`/brokers/${id}/details`} />}
          </Switch>
        </section>
      </main>
    </div>
  );
};
