import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import DisTable from "src/components/Table/Table";
import useVariableFormatting from "src/hooks/useVariableFormatting";
import { IIOCampaignDetailResponse } from "src/store/models/campaign.model";
import { IIOTemplateStep, MessageQueueMessage } from "src/store/models/template.model";
import * as S from "../../constants/StringConstants";
import { IRootState } from "../../store/reducers";
import { fetchCampaign } from "../../store/reducers/campaign";
import { deleteCampaign } from "../CampaignBuilder/api";
import { addNewlines } from "../CampaignBuilder/utils";
import "./CampaignDetail.scss";
import { StepCharts } from "./StepCharts";
import { formatCADateTime, formatDateTime, stopSendingCampaignNotifications } from "./utils";

export const MessageStatuses = {
  1: "To send",
  2: "Sent",
  4: "Error",
  // 5: "Error",?
  6: "Paused",
};

enum QueueMessageStatus {
  ToSend = 1,
  Sent = 2,
  Error = 5,
  Paused = 6,
}

enum CampaignTypes {
  IO = 1,
  Proposal = 2,
}

interface ICampaignProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{
      id: string;
    }> {}

interface State {
  selectedTabIndex: number;
}

interface PreviewProps {
  stepInformation: MessageQueueMessage;
}

const PreviewEmail = (props: PreviewProps) => {
  const { stepInformation } = props;

  const editorRef = useVariableFormatting(null, stepInformation.content);

  return (
    <Box className="box">
      <Row>
        <Col>
          <strong>{S.TPL_MAIL_SUBJECT}</strong>: {stepInformation.subject}
        </Col>
      </Row>
      <Row>
        <Col>
          <ReactQuill ref={editorRef} theme="bubble" defaultValue={stepInformation.content} readOnly />
        </Col>
      </Row>
    </Box>
  );
};

const PreviewSms = (props: PreviewProps) => {
  const { stepInformation } = props;

  const editorRef = useVariableFormatting(null, addNewlines(stepInformation.content));

  return (
    <Box
      padding={2}
      border={1}
      borderRadius={2}
      borderColor={"divider"}
      className="preview-box"
      marginBottom={"1rem"}
      sx={{ background: "white" }}>
      <Row>
        <Col>
          <strong>{S.TPL_SMS_TITLE}</strong>:
        </Col>
      </Row>
      <Row>
        <Col>
          <ReactQuill ref={editorRef} theme="bubble" defaultValue={stepInformation.content} readOnly />
        </Col>
      </Row>
    </Box>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

interface StepSummaryProps {
  step: IIOTemplateStep;
}

const StepSummary = ({ step }: StepSummaryProps) => {
  // TODO: actionDatetime is not reliable, check wjy
  if (step.messageQueues) {
    return <></>;
  }
  const { dueDateTime } = step.messageQueues[0];
  return (
    <Box className="box">
      <dl className="summary-list">
        <dt>Date time</dt>
        <dd>{formatDateTime(dueDateTime)}</dd>
      </dl>
    </Box>
  );
};

enum QueueAction {
  Pause = 1,
  Resume = 2,
}

class CampaignDetails extends React.Component<ICampaignProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabIndex: 0,
    };
  }

  componentDidMount(): void {
    this.reload();
  }

  getCampaignDetails = () => {
    return this.props.campaign.map[this.getCampaignId()] as unknown as IIOCampaignDetailResponse;
  };

  getCampaignId = () => {
    return this.props.match.params.id;
  };

  reload() {
    const { fetchCampaign, match } = this.props;
    fetchCampaign(match.params.id);
  }

  pauseForUser = async (item: MessageQueueMessage, action: QueueAction) => {
    const campaignDetails = this.getCampaignDetails();
    await stopSendingCampaignNotifications(campaignDetails.campaignId, item.contactIndex, action);
  };

  deleteCampaign = async () => {
    if (confirm(S.CAMP_CONFIRM_DELETE_CAMPAIGN)) {
      await deleteCampaign(this.getCampaignId());
      this.props.history.push("/campaigns/all");
    }
  };

  editCampaign = async () => {
    this.props.history.push("/campaigns/new?id=" + this.getCampaignId());
  };

  render() {
    const campaignDetails = this.getCampaignDetails() as any;

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      this.setState({ selectedTabIndex: newValue });
    };

    if (!campaignDetails || !campaignDetails.steps) return null;

    const { selectedTabIndex } = this.state;

    return (
      <div className="table-view__content campaign-details">
        <div className="nav">
          <h1 className="heading1 grey--light">
            {[campaignDetails.campaignName, campaignDetails.carrierName].filter(x => !!x).join(" - ")}
          </h1>
          <div>
            <Button className="button__grey" type="button" onClick={this.editCampaign}>
              {S.CAMP_EDIT_CAMPAIGN}
            </Button>
            <button className="button__grey" type="button" onClick={this.deleteCampaign}>
              {S.CAMP_DELETE_CAMPAIGN}
            </button>
          </div>
        </div>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={selectedTabIndex} onChange={handleChange}>
            <Tab sx={{ fontSize: "10pt" }} label={S.CAMP_OVERVIEW} />
            {campaignDetails.steps.map((step, index) => {
              return (
                <Tab
                  key={step.stepId}
                  sx={{ fontSize: "10pt" }}
                  label={`${index + 1}. ${step.kind === "email" ? "Email" : "SMS"}`}
                />
              );
            })}
          </Tabs>
        </Box>

        {/* overview */}
        <CustomTabPanel value={selectedTabIndex} index={0}>
          <dl className="summary-list">
            <dt>{S.CAMP_CARRIER_NAME}</dt>
            <dd>{campaignDetails.carrierType || "-"}</dd>
            <dt>{S.CAMP_CREATED_BY}</dt>
            <dd>{campaignDetails.userName}</dd>
            <dt>{S.CAMP_CREATED_DATE}</dt>
            <dd>{formatCADateTime(campaignDetails.updatedDate)}</dd>
            <dt>{S.CAMP_CONTACTS}</dt>
            <dd>{campaignDetails.noOfContacts}</dd>
            <dt>{S.CS_JOURNEY} (PST)</dt>
            <dd>
              <ol>
                {campaignDetails.steps.map((step, index) => {
                  if (!step.messageQueues) {
                    return <></>;
                  }
                  const { dueDateTime } = step.messageQueues[0];
                  return (
                    <li key={step.stepId}>
                      {step.kind === "email" ? "Email" : "SMS"} @ {formatCADateTime(dueDateTime)}
                    </li>
                  );
                })}
              </ol>
            </dd>
          </dl>
        </CustomTabPanel>

        {campaignDetails.steps.map((step, index) => {
          return (
            <CustomTabPanel key={step.stepId} value={selectedTabIndex} index={index + 1}>
              <StepSummary step={step} />
              <StepCharts step={step} />
              <MessageList
                key={step.messageQueues?.map(m => m.status).join(",")}
                step={step}
                isProposal={campaignDetails.campaignTypeId === CampaignTypes.Proposal}
                rowData={JSON.parse(campaignDetails.rowData)}
                pauseForUser={async (item: MessageQueueMessage) => {
                  await this.pauseForUser(item, QueueAction.Pause);
                  this.reload();
                }}
                resumeForUser={async (item: MessageQueueMessage) => {
                  await this.pauseForUser(item, QueueAction.Resume);
                  this.reload();
                }}
              />
              {step.kind === "email" ? <PreviewEmail stepInformation={step} /> : <PreviewSms stepInformation={step} />}
            </CustomTabPanel>
          );
        })}
      </div>
    );
  }
}

interface MessageListProps {
  step: IIOTemplateStep;
  rowData: any;
  isProposal: boolean;
  pauseForUser: (message: MessageQueueMessage) => void;
  resumeForUser: (message: MessageQueueMessage) => void;
}

const MessageList = ({ step, isProposal, rowData, pauseForUser, resumeForUser }: MessageListProps) => {
  const renderEmail = (list: string) => {
    const emails = list
      .split(",")
      .map(x => x.trim())
      .filter(x => !!x);
    if (emails.length >= 1) {
      return <span title={emails.join("\n")}>{emails[0]}</span>;
    } else {
      return null;
    }
  };

  const columns = [
    {
      key: "status",
      title: S.CAMP_COL_STATUS,
      style: { minWidth: "6rem", width: "12rem" },
      sortKey: "status",
      render: item => {
        return MessageStatuses[item.status];
      },
    },
    ...(isProposal
      ? [
          {
            key: "policyNumber",
            title: S.CAMP_COL_PROPOSAL,
            style: { minWidth: "6rem", width: "12rem" },
            sortKey: "status",
            render: item => {
              const id = rowData[item.contactIndex - 1].policyNumber;
              return (
                <Link target="_blank" to={`/proposals/${id}`}>
                  {id}
                </Link>
              );
            },
          },
        ]
      : []),
    {
      key: "dueDateTime",
      title: S.CAMP_COL_DUE_DATE,
      style: { minWidth: "6rem", width: "12rem" },
      sortKey: "dueDateTime",
      render: item => {
        return formatCADateTime(item.dueDateTime);
      },
    },
    {
      key: "sentDateTime",
      title: S.CAMP_COL_SENT_DATE,
      style: { minWidth: "6rem", width: "12rem" },
      sortKey: "sentDateTime",
      render: item => {
        return formatCADateTime(item.sentDateTime);
      },
    },
    {
      key: "to",
      title: S.CAMP_COL_TO,
      style: { minWidth: "6rem", width: "12rem" },
      sortKey: "to",
      render: item => {
        return renderEmail(item.to);
      },
    },
    {
      key: "cc",
      title: S.CAMP_COL_CC,
      style: { minWidth: "6rem", width: "12rem" },
      sortKey: "cc",
      render: item => {
        return renderEmail(item.cc);
      },
    },
    {
      key: "error",
      title: S.CAMP_COL_ERRORS,
      style: { minWidth: "6rem", width: "12rem" },
      sortKey: "error",
    },
    {
      key: "delete",
      title: "",
      style: { width: "7rem" },
      render: item => {
        if (item.status == QueueMessageStatus.ToSend) {
          return (
            <Button className="button__white button__white--small" onClick={() => pauseForUser(item)}>
              {S.CAMP_PAUSE}
            </Button>
          );
        }
        if (item.status == QueueMessageStatus.Paused) {
          return (
            <Button className="button__white button__white--small" onClick={() => resumeForUser(item)}>
              {S.CAMP_RESUME}
            </Button>
          );
        }
        return <></>;
      },
    },
  ];

  const total = step.messageQueues.length;
  const listOptions = {};
  const map = null;
  const projection = null;
  return (
    <>
      <DisTable<MessageQueueMessage>
        columns={columns}
        initialState={{ rows: 25, map, projection, total, ...listOptions }}
        onUpdate={async ({ page, rows, sort, query }) => {
          return {
            total,
            data: step.messageQueues.slice((page - 1) * rows, page * rows),
          };
        }}></DisTable>
    </>
  );
};

const mapStateToProps = ({ campaign }: IRootState) => ({ campaign });
const mapDispatchToProps = { fetchCampaign };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetails);
