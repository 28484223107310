import { get } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Container } from "reactstrap";
import { IRootState } from "../../store/reducers";
import { downloadPublicDocument } from "../../store/reducers/secureUpload";
import "./FileRef.scss";

export interface IFileRefProps extends StateProps, DispatchProps, RouteComponentProps {}
export interface IFileRefState {
  mimetype: string;
  url: string | null;
}
export class FileRef extends React.Component<IFileRefProps, IFileRefState> {
  private ref: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.state = {
      mimetype: "text/html",
      url: null,
    };
    this.ref = React.createRef();
  }

  downloadDocument = async (proposalID: string, documentID: string) => {
    const result = await this.props.downloadPublicDocument(proposalID, documentID);
    const data = get(result, "value.data");
    let mimetype = get(result, "value.headers.content-type", "text/html");
    if (mimetype === "application/octet-stream") {
      mimetype = "application/pdf";
    }
    const url = URL.createObjectURL(data);
    this.setState({
      mimetype,
      url,
    });
  };

  componentDidMount() {
    const { pid, did } = this.props.match.params as any;
    if (pid && did) {
      this.downloadDocument(pid, did);
    }
  }

  render() {
    const { mimetype, url } = this.state;
    if (url && mimetype) {
      return <embed src={url} width={window.innerWidth} height={window.innerHeight} type={mimetype} />;
    }
    return (
      <Container fluid className="print-container">
        <h1>Your DIS Analyzer will download shortly.....</h1>
        <h3>You may need to refresh this page if it doesn't load in 30 seconds.</h3>
      </Container>
    );
  }
}

const mapStateToProps = ({ SecureUploadState, proposalOptions: { statusList } }: IRootState) => ({});

const mapDispatchToProps = {
  downloadPublicDocument,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FileRef);
